import { createSlice } from "@reduxjs/toolkit";
import { fetchUser } from "./actions";

const initialState = {
  organizations: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  agencies: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  streamers: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  users: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    forceClearAllUserState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // THIS IS REUSABLE FOR FETCHING ORGANIZATION, AGENCIES AND STREAMERS
      .addCase(fetchUser.pending, (state, action) => {
        const { userType } = action.meta.arg
        state[userType].isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const { userType } = action.meta.arg
        const data = action.payload.d;
        const message = action.payload.m;

        state[userType].data = data;
        state[userType].isLoading = false;
        state[userType].success = `Successfully Fetched ${userType} Data`;
        state[userType].message = message;

      })
      .addCase(fetchUser.rejected, (state, action) => {
        const { userType } = action.meta.arg
        state[userType].isLoading = false;
        state[userType].success = false;
        state[userType].error = action.payload.m || `Error Fetched ${userType} Data`;
        state[userType].message = action.payload.m || "An error occurred";
      })

  },
});

export const { 
} = userSlice.actions;

export default userSlice.reducer;
